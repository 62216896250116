import { type Injector } from '@angular/core';
import { BaseWidget } from '@sds/widget-gridster';

export class OpenTasksWidget extends BaseWidget<never> {
  static readonly id = 'open-tasks';
  static readonly publicFriendly = false;

  static factory(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    return new OpenTasksWidget(injector, metaLinkId, x, y, rows, cols);
  }

  constructor(injector: Injector, metaLinkId: string, x: number, y: number, rows: number, cols: number) {
    super(injector, OpenTasksWidget.id, OpenTasksWidget.publicFriendly, metaLinkId, x, y, rows, cols);
    this.resolveComponent(async () => {
      const module = await import('../widgets/open-tasks/open-tasks.component');
      return Object.values(module)[0];
    });
  }
}
